export default {
  translation: {
    common: {
      delete: 'Eliminar',
      deleteModalTitle: '¿Estás seguro de eliminar este elemento?',
      ok: 'Sí',
      cancel: 'No',
      total: 'Total',
      rename: 'Renombrar',
      name: 'Nombre',
      save: 'Guardar',
      namePlaceholder: 'Por favor ingresa un nombre',
      next: 'Siguiente',
      create: 'Crear',
      edit: 'Editar',
      upload: 'Subir',
      english: 'Ingles',
      spanish: 'Español',
      chinese: 'Chino simplificado',
      traditionalChinese: 'Chino tradicional',
      language: 'Idioma',
      languageMessage: '¡Por favor ingresa tu idioma!',
      languagePlaceholder: 'Selecciona tu idioma',
      copy: 'Copiar',
      copied: 'Copiado',
      comingSoon: 'Próximamente',
      download: 'Descargar',
      close: 'Cerrar',
      preview: 'Vista previa',
      move: 'Mover',
      warn: 'Advertencia',
    },
    login: {
      login: 'Iniciar sesión',
      signUp: 'Registrarse',
      loginDescription: '¡Estamos emocionados de verte de nuevo!',
      registerDescription: '¡Nos alegra tenerte a bordo!',
      emailLabel: 'Correo electrónico',
      emailPlaceholder: 'Por favor ingresa tu correo electrónico',
      passwordLabel: 'Contraseña',
      passwordPlaceholder: 'Por favor ingresa tu contraseña',
      rememberMe: 'Recordarme',
      signInTip: '¿No tienes una cuenta?',
      signUpTip: '¿Ya tienes una cuenta?',
      nicknameLabel: 'Apodo',
      nicknamePlaceholder: 'Por favor ingresa tu apodo',
      register: 'Crear una cuenta',
      continue: 'Continuar',
      title: 'Pruebas asistentes inteligentes.',
      description:
        'Tecnología RAG de primer nivel. Crea bases de conocimiento y asistentes inteligentes.',
      review: 'Fiscalía General de la Nación',
    },
    header: {
      knowledgeBase: 'Base de conocimiento',
      chat: 'Chat',
      register: 'Registro',
      signin: 'Iniciar sesión',
      home: 'Inicio',
      setting: 'Configuración de usuario',
      logout: 'Cerrar sesión',
      fileManager: 'Gestión de archivos',
      flow: 'Agente',
      search: 'Buscar',
    },
    knowledgeList: {
      welcome: 'Bienvenido de nuevo',
      description: '¿Qué base de conocimiento vamos a usar hoy?',
      createKnowledgeBase: 'Crear base de conocimiento',
      name: 'Nombre',
      namePlaceholder: '¡Por favor ingresa un nombre!',
      doc: 'Documentos',
      searchKnowledgePlaceholder: 'Buscar',
    },
    knowledgeDetails: {
      dataset: 'Conjunto de datos',
      testing: 'Pruebas de recuperación',
      files: 'Archivos',
      configuration: 'Configuración',
      name: 'Nombre',
      namePlaceholder: '¡Por favor ingresa un nombre!',
      doc: 'Documentos',
      datasetDescription:
        '😉 Las preguntas solo pueden ser respondidas después de que el análisis sea exitoso.',
      addFile: 'Agregar archivo',
      searchFiles: 'Buscar tus archivos',
      localFiles: 'Archivos locales',
      emptyFiles: 'Crear archivo vacío',
      webCrawl: 'Rastreo web',
      chunkNumber: 'Número de fragmentos',
      uploadDate: 'Fecha de carga',
      chunkMethod: 'Método de fragmentación',
      enabled: 'Habilitar',
      disabled: 'Deshabilitar',
      action: 'Acción',
      parsingStatus: 'Estado de análisis',
      processBeginAt: 'Proceso iniciado en',
      processDuration: 'Duración del proceso',
      progressMsg: 'Mensaje de progreso',
      testingDescription:
        '¡Último paso! Después del éxito, deja el resto al AI de TT Nectar AI(科传花蜜AI智能体).',
      similarityThreshold: 'Umbral de similitud',
      similarityThresholdTip:
        'Usamos una puntuación de similitud híbrida para evaluar la distancia entre dos líneas de texto. Se pondera la similitud de palabras clave y la similitud coseno de vectores. Si la similitud entre la consulta y el fragmento es menor que este umbral, el fragmento será filtrado.',
      vectorSimilarityWeight: 'Peso de similitud de palabras clave',
      vectorSimilarityWeightTip:
        'Usamos una puntuación de similitud híbrida para evaluar la distancia entre dos líneas de texto. Se pondera la similitud de palabras clave y la similitud coseno de vectores o la puntuación de reordenamiento (0~1). La suma de ambos pesos es 1.0.',
      testText: 'Texto de prueba',
      testTextPlaceholder: '¡Por favor ingresa tu pregunta!',
      testingLabel: 'Prueba',
      similarity: 'Similitud híbrida',
      termSimilarity: 'Similitud de términos',
      vectorSimilarity: 'Similitud de vectores',
      hits: 'Resultados',
      view: 'Ver',
      filesSelected: 'Archivos seleccionados',
      upload: 'Subir',
      run: 'Analizar Gramaticalmente',
      runningStatus0: 'Irresoluto',
      runningStatus1: 'Analizando',
      runningStatus2: 'CANCELADO',
      runningStatus3: 'ÉXITO',
      runningStatus4: 'FALLIDO',
      pageRanges: 'Rangos de páginas',
      pageRangesTip:
        'rango de páginas: Define los rangos de páginas que necesitan ser analizados. Las páginas que no estén incluidas en estos rangos serán ignoradas.',
      fromPlaceholder: 'Desde',
      fromMessage: 'Falta el número de página inicial',
      toPlaceholder: 'Hasta',
      toMessage: 'Falta el número de página final (excluido)',
      layoutRecognize: 'Reconocimiento de disposición',
      layoutRecognizeTip:
        'Usa modelos visuales para el análisis de disposición y así identificar mejor la estructura del documento, encontrar dónde están los títulos, bloques de texto, imágenes y tablas. Sin esta función, solo se obtendrá el texto plano del PDF.',
      taskPageSize: 'Tamaño de la tarea por página',
      taskPageSizeMessage:
        '¡Por favor ingresa el tamaño de la tarea por página!',
      taskPageSizeTip: `Si usas el reconocimiento de disposición, el archivo PDF se dividirá en grupos de páginas consecutivas. El análisis de disposición se realizará en paralelo entre los grupos para aumentar la velocidad de procesamiento. El 'Tamaño de tarea por página' determina el tamaño de los grupos. Cuanto mayor sea el tamaño de la página, menor será la posibilidad de dividir texto continuo entre páginas en diferentes fragmentos.`,
      addPage: 'Agregar página',
      greaterThan: 'El valor actual debe ser mayor que el valor final.',
      greaterThanPrevious: 'El valor actual debe ser mayor que el anterior.',
      selectFiles: 'Seleccionar archivos',
      changeSpecificCategory: 'Cambiar categoría específica',
      uploadTitle: 'Haz clic o arrastra el archivo a esta área para cargar',
      uploadDescription:
        'Soporte para carga individual o múltiple. Está estrictamente prohibido cargar datos de la empresa u otros archivos prohibidos.',
      chunk: 'Fragmento',
      bulk: 'Masa',
      cancel: 'Cancelar',
      rerankModel: 'Modelo de reordenamiento',
      rerankPlaceholder: 'Por favor selecciona',
      rerankTip: `Si está vacío, se utilizan los embeddings de la consulta y los fragmentos para calcular la similitud coseno del vector. De lo contrario, se usa la puntuación de reordenamiento en lugar de la similitud coseno del vector.`,
      topK: 'Top-K',
      topKTip: `K fragmentos serán alimentados a los modelos de reordenamiento.`,
      delimiter: `Delimitador`,
      html4excel: 'Excel a HTML',
      html4excelTip: `Excel se analizará en una tabla HTML o no. Si es FALSO, cada fila en Excel se formará como un fragmento.`,
    },

    // Otros bloques de traducción
    // Continua con la misma estructura
    chat: {
      newConversation: 'Nueva conversación',
      createAssistant: 'Crear un asistente',
      assistantSetting: 'Configuración del asistente',
      promptEngine: 'Motor de prompts',
      modelSetting: 'Configuración del modelo',
      chat: 'Chat',
      newChat: 'Nuevo chat',
      send: 'Enviar',
      sendPlaceholder: 'Enviar mensaje al Asistente...',
      chatConfiguration: 'Configuración del Chat',
      chatConfigurationDescription:
        'Aquí, personaliza un asistente dedicado para tus bases de conocimiento especiales 💕',
      assistantName: 'Nombre del asistente',
      assistantNameMessage: 'El nombre del asistente es obligatorio',
      namePlaceholder: 'p.ej. Resume Jarvis',
      assistantAvatar: 'Avatar del asistente',
      language: 'Idioma',
      emptyResponse: 'Respuesta vacía',
      emptyResponseTip: `Si no se recupera nada con la pregunta del usuario en la base de conocimiento, se usará esto como respuesta. Si quieres que el LLM emita su propia opinión cuando no se recupere nada, deja esto en blanco.`,
      setAnOpener: 'Establecer un saludo',
      setAnOpenerInitial: `¡Hola! Soy tu asistente, ¿en qué puedo ayudarte?`,
      setAnOpenerTip: '¿Cómo quieres dar la bienvenida a tus clientes?',
      knowledgeBases: 'Bases de conocimiento',
      knowledgeBasesMessage: 'Por favor selecciona',
      knowledgeBasesTip: 'Selecciona las bases de conocimiento asociadas.',
      system: 'Sistema',
      systemInitialValue: `Eres un asistente inteligente. Por favor resume el contenido de la base de conocimiento para responder la pregunta. Enumera los datos en la base de conocimiento y responde con detalle. Cuando todo el contenido de la base de conocimiento sea irrelevante para la pregunta, tu respuesta debe incluir la frase "¡La respuesta que buscas no se encuentra en la base de conocimiento!". Las respuestas necesitan considerar el historial de chat.
        Aquí está la base de conocimiento:
        {knowledge}
        Esa es la base de conocimiento.`,
      systemMessage: '¡Por favor ingresa!',
      systemTip:
        'Instrucciones que necesitas que el LLM siga cuando responda preguntas, como el diseño de carácter, la longitud de la respuesta y el idioma de la respuesta.',
      topN: 'Top N',
      topNTip: `No todos los fragmentos cuya puntuación de similitud esté por encima del "umbral de similitud" serán enviados a los LLMs. Los LLMs solo pueden ver estos "Top N" fragmentos.`,
      variable: 'Variable',
      variableTip: `Si usas APIs de diálogo, las variables pueden ayudarte a chatear con tus clientes usando diferentes estrategias.
        Las variables se utilizan para completar la parte "Sistema" del prompt para darle una pista al LLM.
        La "base de conocimiento" es una variable muy especial que se completará con los fragmentos recuperados.
        Todas las variables en "Sistema" deben estar entre llaves.`,
      add: 'Agregar',
      key: 'Clave',
      optional: 'Opcional',
      operation: 'Operación',
      model: 'Modelo',
      modelTip: 'Modelo de chat de gran lenguaje',
      modelMessage: '¡Por favor selecciona!',
      freedom: 'Libertad',
      improvise: 'Improvisar',
      precise: 'Preciso',
      balance: 'Equilibrado',
      freedomTip: `'Preciso' significa que el LLM será conservador y responderá tu pregunta con cautela. 'Improvisar' significa que el LLM hablará mucho y con libertad. 'Equilibrado' es un punto intermedio entre cautela y libertad.`,
      temperature: 'Temperatura',
      temperatureMessage: 'La temperatura es obligatoria',
      temperatureTip:
        'Este parámetro controla la aleatoriedad de las predicciones del modelo. Una temperatura más baja hace que el modelo sea más seguro en sus respuestas, mientras que una temperatura más alta lo hace más creativo y diverso.',
      topP: 'Top P',
      topPMessage: 'Top P es obligatorio',
      topPTip:
        'También conocido como “muestreo de núcleo”, este parámetro establece un umbral para seleccionar un conjunto más pequeño de palabras de las que muestrear. Se enfoca en las palabras más probables, descartando las menos probables.',
      presencePenalty: 'Penalización por presencia',
      presencePenaltyMessage: 'La penalización por presencia es obligatoria',
      presencePenaltyTip:
        'Esto desanima al modelo a repetir la misma información al penalizar las palabras que ya han aparecido en la conversación.',
      frequencyPenalty: 'Penalización por frecuencia',
      frequencyPenaltyMessage: 'La penalización por frecuencia es obligatoria',
      frequencyPenaltyTip:
        'Similar a la penalización por presencia, esto reduce la tendencia del modelo a repetir las mismas palabras con frecuencia.',
      maxTokens: 'Máximo de tokens',
      maxTokensMessage: 'El máximo de tokens es obligatorio',
      maxTokensTip:
        'Esto establece la longitud máxima de la salida del modelo, medida en el número de tokens (palabras o piezas de palabras).',
      maxTokensInvalidMessage:
        'Por favor, ingresa un número válido para Max Tokens.',
      maxTokensMinMessage: 'Max Tokens no puede ser menor que 0.',
      quote: 'Mostrar cita',
      quoteTip: '¿Debe mostrarse la fuente del texto original?',
      selfRag: 'Self-RAG',
      selfRagTip: 'Consulta: https://huggingface.co/papers/2310.11511',
      overview: 'ID del Chat',
      pv: 'Número de mensajes',
      uv: 'Número de usuarios activos',
      speed: 'Velocidad de salida de tokens',
      tokens: 'Consumir el número de tokens',
      round: 'Número de interacciones en la sesión',
      thumbUp: 'Satisfacción del cliente',
      preview: 'Vista previa',
      embedded: 'Incrustado',
      serviceApiEndpoint: 'Endpoint del API de Servicio',
      apiKey: 'Clave API',
      apiReference: 'Documentación del API',
      dateRange: 'Rango de fechas:',
      backendServiceApi: 'Servidor API',
      createNewKey: 'Crear nueva clave',
      created: 'Creado',
      action: 'Acción',
      embedModalTitle: 'Incrustar en el sitio web',
      comingSoon: 'Próximamente',
      fullScreenTitle: 'Incrustación completa',
      fullScreenDescription:
        'Incrusta el siguiente iframe en tu sitio web en la ubicación deseada',
      partialTitle: 'Incrustación parcial',
      extensionTitle: 'Extensión de Chrome',
      tokenError: '¡Por favor crea un token API primero!',
      searching: 'buscando...',
      parsing: 'Analizando',
      uploading: 'Subiendo',
      uploadFailed: 'Error al subir',
      regenerate: 'Regenerar',
      read: 'Leer contenido',
      tts: 'Texto a voz',
      ttsTip:
        'Para reproducir la voz utilizando conversión de voz, selecciona primero el modelo TTS (conversión de texto a voz) en la configuración.',
      relatedQuestion: 'Pregunta relacionada',
      answerTitle: 'R',
      multiTurn: 'Optimización de múltiples turnos',
      multiTurnTip:
        'En conversaciones de múltiples rondas, la consulta a la base de conocimiento se optimiza. El gran modelo se llamará para consumir tokens adicionales.',
      description: 'Description of assistant',
    },
    setting: {
      profile: 'Perfil',
      profileDescription: 'Actualiza tu foto y tus datos personales aquí.',
      maxTokens: 'Máximo de tokens',
      maxTokensMessage: 'El máximo de tokens es obligatorio',
      maxTokensTip:
        'Esto establece la longitud máxima de la salida del modelo, medida en el número de tokens (palabras o piezas de palabras).',
      maxTokensInvalidMessage:
        'Por favor, ingresa un número válido para Max Tokens.',
      maxTokensMinMessage: 'Max Tokens no puede ser menor que 0.',
      password: 'Contraseña',
      passwordDescription:
        'Por favor ingresa tu contraseña actual para cambiarla.',
      model: 'Proveedores de Modelo',
      modelDescription:
        'Configura el parámetro del modelo y la clave API aquí.',
      team: 'Equipo',
      system: 'Sistema',
      logout: 'Cerrar sesión',
      api: 'API',
      username: 'Nombre de usuario',
      usernameMessage: '¡Por favor ingresa tu nombre de usuario!',
      photo: 'Tu foto',
      photoDescription: 'Esta será mostrada en tu perfil.',
      colorSchema: 'Esquema de colores',
      colorSchemaMessage: '¡Por favor selecciona tu esquema de colores!',
      colorSchemaPlaceholder: 'selecciona tu esquema de colores',
      bright: 'Brillante',
      dark: 'Oscuro',
      timezone: 'Zona horaria',
      timezoneMessage: '¡Por favor ingresa tu zona horaria!',
      timezonePlaceholder: 'selecciona tu zona horaria',
      email: 'Dirección de correo electrónico',
      emailDescription:
        'Una vez registrado, no se puede cambiar el correo electrónico.',
      currentPassword: 'Contraseña actual',
      currentPasswordMessage: '¡Por favor ingresa tu contraseña!',
      newPassword: 'Nueva contraseña',
      newPasswordMessage: '¡Por favor ingresa tu nueva contraseña!',
      newPasswordDescription:
        'Tu nueva contraseña debe tener más de 8 caracteres.',
      confirmPassword: 'Confirmar nueva contraseña',
      confirmPasswordMessage: '¡Por favor confirma tu nueva contraseña!',
      confirmPasswordNonMatchMessage:
        '¡Las contraseñas que ingresaste no coinciden!',
      cancel: 'Cancelar',
      addedModels: 'Modelos agregados',
      modelsToBeAdded: 'Modelos a agregar',
      addTheModel: 'Agregar el modelo',
      apiKey: 'Clave API',
      apiKeyMessage:
        'Por favor ingresa la clave API (ignora esto si el modelo está implementado localmente).',
      apiKeyTip:
        'La clave API puede obtenerse registrándose con el proveedor correspondiente de LLM.',
      showMoreModels: 'Mostrar más modelos',
      baseUrl: 'URL base',
      baseUrlTip:
        'Si tu clave API es de OpenAI, ignora esto. Cualquier otro proveedor intermedio proporcionará esta URL base junto con la clave API.',
      modify: 'Modificar',
      systemModelSettings: 'Configuración del modelo del sistema',
      chatModel: 'Modelo de chat',
      chatModelTip:
        'El modelo LLM de chat predeterminado que todas las nuevas bases de conocimiento utilizarán.',
      embeddingModel: 'Modelo de embeddings',
      embeddingModelTip:
        'El modelo de embeddings predeterminado que todas las nuevas bases de conocimiento utilizarán.',
      img2txtModel: 'Modelo de img2txt',
      img2txtModelTip:
        'El modelo multimódulo predeterminado que todas las nuevas bases de conocimiento utilizarán. Puede describir una imagen o video.',
      sequence2txtModel: 'Modelo de secuencia a texto',
      sequence2txtModelTip:
        'El modelo ASR predeterminado que todas las nuevas bases de conocimiento utilizarán. Usa este modelo para transcribir voces a texto correspondiente.',
      rerankModel: 'Modelo de reordenamiento',
      rerankModelTip:
        'El modelo de reordenamiento predeterminado que se usará para reordenar los fragmentos recuperados por las preguntas de los usuarios.',
      ttsModel: 'Modelo TTS',
      ttsModelTip:
        'El modelo TTS predeterminado que se usará para generar discurso durante las conversaciones cuando se solicite.',
      workspace: 'Espacio de trabajo',
      upgrade: 'Actualizar',
      addLlmTitle: 'Agregar LLM',
      modelName: 'Nombre del modelo',
      modelID: 'ID del modelo',
      modelUid: 'UID del modelo',
      modelNameMessage: '¡Por favor ingresa el nombre de tu modelo!',
      modelType: 'Tipo de modelo',
      modelTypeMessage: '¡Por favor ingresa el tipo de tu modelo!',
      addLlmBaseUrl: 'URL base',
      baseUrlNameMessage: '¡Por favor ingresa tu URL base!',
      vision: '¿Soporta visión?',
      ollamaLink: 'Cómo integrar {{name}}',
      FishAudioLink: 'Cómo usar FishAudio',
      TencentCloudLink: 'Cómo usar TencentCloud ASR',
      volcModelNameMessage: '¡Por favor ingresa el nombre de tu modelo!',
      addEndpointID: 'EndpointID del modelo',
      endpointIDMessage: '¡Por favor ingresa el EndpointID del modelo!',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: '¡Por favor ingresa tu ARK_API_KEY!',
      bedrockModelNameMessage: '¡Por favor ingresa el nombre de tu modelo!',
      addBedrockEngineAK: 'CLAVE DE ACCESO',
      bedrockAKMessage: '¡Por favor ingresa tu CLAVE DE ACCESO!',
      addBedrockSK: 'CLAVE SECRETA',
      bedrockSKMessage: '¡Por favor ingresa tu CLAVE SECRETA!',
      bedrockRegion: 'Región AWS',
      bedrockRegionMessage: '¡Por favor selecciona!',
      'us-east-1': 'Este de EE. UU. (Virginia del Norte)',
      'us-west-2': 'Oeste de EE. UU. (Oregón)',
      'ap-southeast-1': 'Asia Pacífico (Singapur)',
      'ap-northeast-1': 'Asia Pacífico (Tokio)',
      'eu-central-1': 'Europa (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (EE. UU. Oeste)',
      'ap-southeast-2': 'Asia Pacífico (Sídney)',
      addHunyuanSID: 'ID Secreto de Hunyuan',
      HunyuanSIDMessage: '¡Por favor ingresa tu ID Secreto!',
      addHunyuanSK: 'Clave Secreta de Hunyuan',
      HunyuanSKMessage: '¡Por favor ingresa tu Clave Secreta!',
      addTencentCloudSID: 'ID Secreto de TencentCloud',
      TencentCloudSIDMessage: '¡Por favor ingresa tu ID Secreto!',
      addTencentCloudSK: 'Clave Secreta de TencentCloud',
      TencentCloudSKMessage: '¡Por favor ingresa tu Clave Secreta!',
      SparkModelNameMessage: 'Por favor selecciona el modelo Spark',
      addSparkAPIPassword: 'Contraseña API de Spark',
      SparkAPIPasswordMessage: '¡Por favor ingresa tu contraseña API!',
      addSparkAPPID: 'APPID de Spark',
      SparkAPPIDMessage: '¡Por favor ingresa tu APPID!',
      addSparkAPISecret: 'API Secret de Spark',
      SparkAPISecretMessage: '¡Por favor ingresa tu API Secret!',
      addSparkAPIKey: 'Clave API de Spark',
      SparkAPIKeyMessage: '¡Por favor ingresa tu Clave API!',
      yiyanModelNameMessage: '¡Por favor ingresa el nombre del modelo!',
      addyiyanAK: 'Clave API de yiyan',
      yiyanAKMessage: '¡Por favor ingresa tu Clave API!',
      addyiyanSK: 'Clave Secreta de yiyan',
      yiyanSKMessage: '¡Por favor ingresa tu Clave Secreta!',
      FishAudioModelNameMessage:
        'Por favor, da un nombre a tu modelo de síntesis de voz',
      addFishAudioAK: 'Clave API de Fish Audio',
      addFishAudioAKMessage: '¡Por favor ingresa tu Clave API!',
      addFishAudioRefID: 'ID de Referencia de FishAudio',
      addFishAudioRefIDMessage:
        '¡Por favor ingresa el ID de Referencia (deja en blanco para usar el modelo predeterminado)!',
      GoogleModelIDMessage: '¡Por favor ingresa el ID de tu modelo!',
      addGoogleProjectID: 'ID del Proyecto',
      GoogleProjectIDMessage: '¡Por favor ingresa tu ID de Proyecto!',
      addGoogleServiceAccountKey:
        'Clave de Cuenta de Servicio (deja en blanco si usas las Credenciales Predeterminadas de la Aplicación)',
      GoogleServiceAccountKeyMessage:
        '¡Por favor ingresa la Clave de Cuenta de Servicio de Google Cloud en formato base64!',
      addGoogleRegion: 'Región de Google Cloud',
      GoogleRegionMessage: '¡Por favor ingresa la Región de Google Cloud!',
      modelProvidersWarn:
        'Por favor agrega tanto el modelo de embeddings como el LLM en <b>Configuración > Proveedores de Modelos</b> primero.',
      apiVersion: 'Versión de la API',
      apiVersionMessage: '¡Por favor ingresa la versión de la API!',
    },
    message: {
      registered: '¡Registrado!',
      logout: 'Cerrar sesión',
      logged: '¡Sesión iniciada!',
      pleaseSelectChunk: '¡Por favor selecciona un fragmento!',
      modified: 'Modificado',
      created: 'Creado',
      deleted: 'Eliminado',
      renamed: 'Renombrado',
      operated: 'Operado',
      updated: 'Actualizado',
      uploaded: 'Subido',
      200: 'El servidor devolvió correctamente los datos solicitados.',
      201: 'Datos creados o modificados con éxito.',
      202: 'Una solicitud ha sido puesta en cola en segundo plano (tarea asíncrona).',
      204: 'Datos eliminados con éxito.',
      400: 'Hubo un error en la solicitud emitida y el servidor no creó ni modificó datos.',
      401: 'El usuario no tiene permisos (token, nombre de usuario o contraseña incorrectos).',
      403: 'El usuario está autorizado, pero el acceso está prohibido.',
      404: 'La solicitud se hizo para un registro que no existe y el servidor no realizó la operación.',
      406: 'El formato solicitado no está disponible.',
      410: 'El recurso solicitado ha sido eliminado permanentemente y no estará disponible nuevamente.',
      413: 'El tamaño total de los archivos cargados a la vez es demasiado grande.',
      422: 'Se produjo un error de validación al crear un objeto.',
      500: 'Ocurrió un error en el servidor, por favor revisa el servidor.',
      502: 'Error de puerta de enlace.',
      503: 'El servicio no está disponible y el servidor está temporalmente sobrecargado o en mantenimiento.',
      504: 'Tiempo de espera de la puerta de enlace agotado.',
      requestError: 'Error en la solicitud',
      networkAnomalyDescription:
        'Hay una anomalía en tu red y no puedes conectarte al servidor.',
      networkAnomaly: 'Anomalía de red',
      hint: 'Pista',
    },
    fileManager: {
      name: 'Nombre',
      uploadDate: 'Fecha de carga',
      knowledgeBase: 'Base de conocimiento',
      size: 'Tamaño',
      action: 'Acción',
      addToKnowledge: 'Vincular a la base de conocimiento',
      pleaseSelect: 'Por favor selecciona',
      newFolder: 'Nueva carpeta',
      file: 'Archivo',
      uploadFile: 'Subir archivo',
      directory: 'Directorio',
      uploadTitle: 'Haz clic o arrastra el archivo a esta área para subir',
      uploadDescription:
        'Soporte para una sola carga o carga múltiple. Está estrictamente prohibido subir datos de la empresa u otros archivos prohibidos.',
      local: 'Subidas locales',
      s3: 'Subidas a S3',
      preview: 'Vista previa',
      fileError: 'Error en el archivo',
      uploadLimit:
        'El tamaño del archivo no puede exceder los 10 MB, y el número total de archivos no puede exceder los 128',
      destinationFolder: 'Carpeta de destino',
    },
    flow: {
      cite: 'Citar',
      citeTip: 'Consejo de cita',
      name: 'Nombre',
      nameMessage: 'Por favor ingresa el nombre',
      description: 'Descripción',
      examples: 'Ejemplos',
      to: 'Para',
      msg: 'Mensajes',
      messagePlaceholder: 'mensaje',
      messageMsg: 'Por favor ingresa un mensaje o elimina este campo.',
      addField: 'Agregar campo',
      addMessage: 'Agregar mensaje',
      loop: 'Bucle',
      loopTip:
        'El bucle es el límite superior del número de iteraciones del componente actual. Si el número de bucles supera este valor, significa que el componente no puede completar la tarea actual, por favor optimiza el agente nuevamente.',
      yes: 'Sí',
      no: 'No',
      key: 'Clave',
      componentId: 'ID del componente',
      add: 'Agregar',
      operation: 'Operación',
      run: 'Ejecutar',
      save: 'Guardar',
      title: 'ID:',
      beginDescription: 'Este es el punto donde comienza el flujo.',
      answerDescription:
        'Un componente que sirve como interfaz entre el humano y el bot, recibiendo las entradas del usuario y mostrando las respuestas del agente.',
      retrievalDescription:
        'Un componente que recupera información de una base de conocimiento especificada y devuelve "Respuesta vacía" si no se encuentra información. Asegúrate de seleccionar la base de conocimiento correcta.',
      generateDescription:
        'Un componente que solicita al LLM generar respuestas. Asegúrate de que el prompt esté configurado correctamente.',
      categorizeDescription:
        'Un componente que utiliza el LLM para clasificar las entradas del usuario en categorías predefinidas. Asegúrate de especificar el nombre, descripción y ejemplos para cada categoría, junto con el componente siguiente correspondiente.',
      relevantDescription:
        'Un componente que usa el LLM para evaluar si la salida previa es relevante para la última consulta del usuario. Asegúrate de especificar el próximo componente para cada resultado.',
      rewriteQuestionDescription:
        'Un componente que refina una consulta del usuario si no se logra recuperar información relevante de la base de conocimiento. Repite este proceso hasta que se alcance el límite superior de iteraciones. Asegúrate de que su entrada provenga de "Relevante" y su salida se dirija a "Recuperación".',
      messageDescription:
        'Un componente que envía un mensaje estático. Si se suministran múltiples mensajes, selecciona uno al azar para enviar. Asegúrate de que su salida sea "Responder", el componente de interfaz.',
      keywordDescription:
        'Un componente que recupera los N resultados principales de búsqueda de la entrada del usuario. Asegúrate de que el valor de TopN esté configurado correctamente antes de su uso.',
      switchDescription:
        'Un componente que evalúa condiciones basadas en la salida de componentes previos y dirige el flujo de ejecución en consecuencia. Permite lógica de ramificación compleja al definir casos y especificar acciones para cada caso o acción predeterminada si no se cumplen las condiciones.',
      wikipediaDescription:
        'Este componente se usa para obtener resultados de búsqueda de wikipedia.org. Típicamente, actúa como un complemento a las bases de conocimiento. Top N especifica el número de resultados de búsqueda que necesitas ajustar.',
      promptText:
        'Por favor resume los siguientes párrafos. Ten cuidado con los números, no inventes datos. Los párrafos son los siguientes: {input} El contenido anterior es el que necesitas resumir.',
      createGraph: 'Crear agente',
      createFromTemplates: 'Crear desde plantillas',
      retrieval: 'Recuperación',
      generate: 'Generar',
      answer: 'Interactuar',
      categorize: 'Categorizar',
      relevant: 'Relevante',
      rewriteQuestion: 'Reescribir pregunta',
      rewrite: 'Reescribir',
      begin: 'Comenzar',
      message: 'Mensaje',
      blank: 'En blanco',
      createFromNothing: 'Crea tu agente desde cero',
      addItem: 'Agregar ítem',
      addSubItem: 'Agregar subítem',
      nameRequiredMsg: 'El nombre es obligatorio',
      nameRepeatedMsg: 'El nombre no puede repetirse',
      keywordExtract: 'Extraer palabra clave',
      keywordExtractDescription:
        'Un componente que extrae palabras clave de la consulta del usuario, con Top N especificando el número de palabras clave a extraer.',
      baidu: 'Baidu',
      baiduDescription:
        'Este componente se usa para obtener resultados de búsqueda de www.baidu.com. Típicamente, actúa como un complemento a las bases de conocimiento. Top N especifica el número de resultados de búsqueda que necesitas ajustar.',
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Un componente que recupera resultados de búsqueda de duckduckgo.com, con TopN especificando el número de resultados de búsqueda. Complementa las bases de conocimiento existentes.',
      channel: 'Canal',
      channelTip:
        'Realizar búsqueda de texto o búsqueda de noticias en la entrada del componente.',
      text: 'Texto',
      news: 'Noticias',
      messageHistoryWindowSize:
        'Tamaño de la ventana del historial de mensajes',
      messageHistoryWindowSizeTip:
        'El tamaño de ventana del historial de conversación que necesita ser visto por el LLM. Cuanto más grande mejor, pero ten cuidado con la longitud máxima de contenido que puede manejar el LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Correo electrónico',
      emailTip:
        'Este componente se usa para obtener resultados de búsqueda de https://pubmed.ncbi.nlm.nih.gov/. Típicamente, actúa como un complemento a las bases de conocimiento. Top N especifica el número de resultados de búsqueda que necesitas ajustar. El correo electrónico es un campo obligatorio.',
      arXiv: 'ArXiv',
      arXivTip:
        'Este componente se usa para obtener resultados de búsqueda de https://arxiv.org/. Típicamente, actúa como un complemento a las bases de conocimiento. Top N especifica el número de resultados de búsqueda que necesitas ajustar.',
      sortBy: 'Ordenar por',
      submittedDate: 'Fecha de envío',
      lastUpdatedDate: 'Última fecha de actualización',
      relevance: 'Relevancia',
      google: 'Google',
      googleTip:
        'Este componente se usa para obtener resultados de búsqueda de https://www.google.com/. Típicamente, actúa como un complemento a las bases de conocimiento. Top N y la clave de API de SerpApi especifican el número de resultados de búsqueda que necesitas ajustar.',
      bing: 'Bing',
      bingTip:
        'Este componente se usa para obtener resultados de búsqueda de https://www.bing.com/. Típicamente, actúa como un complemento a las bases de conocimiento. Top N y la clave de suscripción de Bing especifican el número de resultados de búsqueda que necesitas ajustar.',
      apiKey: 'Clave API',
      country: 'País&Región',
      language: 'Idioma',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Este componente se usa para obtener resultados de búsqueda de https://scholar.google.com/. Típicamente, actúa como un complemento a las bases de conocimiento. Top N especifica el número de resultados de búsqueda que necesitas ajustar.',
      yearLow: 'Año mínimo',
      yearHigh: 'Año máximo',
      patents: 'Patentes',
      data: 'Datos',
      deepL: 'DeepL',
      deepLDescription:
        'Este componente se usa para obtener traducciones de https://www.deepl.com/. Típicamente, proporciona un resultado de traducción más especializado.',
      authKey: 'Clave de autenticación',
      sourceLang: 'Idioma fuente',
      targetLang: 'Idioma objetivo',
      gitHub: 'GitHub',
      githubDescription:
        'Este componente se usa para buscar en el repositorio de https://github.com/. Top N especifica el número de resultados de búsqueda a ajustar.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Este componente se usa para obtener traducciones de https://fanyi.baidu.com/. Típicamente, proporciona un resultado de traducción más especializado.',
      appid: 'ID de la aplicación',
      secretKey: 'Clave secreta',
      domain: 'Dominio',
      transType: 'Tipo de traducción',
      baiduSecretKeyOptions: {
        translate: 'Traducción general',
        fieldtranslate: 'Traducción especializada',
      },
      baiduDomainOptions: {
        it: 'Tecnología de la información',
        finance: 'Finanzas y economía',
        machinery: 'Fabricación de maquinaria',
        senimed: 'Biomedicina',
        novel: 'Literatura en línea',
        academic: 'Papel académico',
        aerospace: 'Aeroespacial',
        wiki: 'Ciencias humanas y sociales',
        news: 'Noticias e información',
        law: 'Leyes y regulaciones',
        contract: 'Contrato',
      },
      baiduSourceLangOptions: {
        auto: 'Detección automática',
        zh: 'Chino',
        en: 'Español',
        yue: 'Cantonés',
        wyw: 'Chino clásico',
        jp: 'Japonés',
        kor: 'Coreano',
        fra: 'Francés',
        spa: 'Español',
        th: 'Tailandés',
        ara: 'Árabe',
        ru: 'Ruso',
        pt: 'Portugués',
        de: 'Alemán',
        it: 'Italiano',
        el: 'Griego',
        nl: 'Holandés',
        pl: 'Polaco',
        bul: 'Búlgaro',
        est: 'Estonio',
        dan: 'Danés',
        fin: 'Finlandés',
        cs: 'Checo',
        rom: 'Rumano',
        slo: 'Esloveno',
        swe: 'Sueco',
        hu: 'Húngaro',
        cht: 'Chino tradicional',
        vie: 'Vietnamita',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Este componente se usa para obtener información meteorológica de https://www.qweather.com/. Puedes obtener pronósticos meteorológicos, índices y calidad del aire.',
      lang: 'Idioma',
      type: 'Tipo',
      webApiKey: 'Clave API Web',
      userType: 'Tipo de usuario',
      timePeriod: 'Período de tiempo',
      qWeatherLangOptions: {
        zh: 'Chino simplificado',
        'zh-hant': 'Chino tradicional',
        en: 'Inglés',
        de: 'Alemán',
        es: 'Español',
        fr: 'Francés',
        it: 'Italiano',
        ja: 'Japonés',
        ko: 'Coreano',
        ru: 'Ruso',
        hi: 'Hindi',
        th: 'Tailandés',
        ar: 'Árabe',
        pt: 'Portugués',
        bn: 'Bengalí',
        ms: 'Malayo',
        nl: 'Holandés',
        el: 'Griego',
        la: 'Latín',
        sv: 'Sueco',
        id: 'Indonesio',
        pl: 'Polaco',
        tr: 'Turco',
        cs: 'Checo',
        et: 'Estonio',
        vi: 'Vietnamita',
        fil: 'Filipino',
        fi: 'Finlandés',
        he: 'Hebreo',
        is: 'Islandés',
        nb: 'Noruego',
      },
      qWeatherTypeOptions: {
        weather: 'Pronóstico del tiempo',
        indices: 'Índice de vida meteorológico',
        airquality: 'Calidad del aire',
      },
      qWeatherUserTypeOptions: {
        free: 'Suscriptor gratuito',
        paid: 'Suscriptor de pago',
      },
      qWeatherTimePeriodOptions: {
        now: 'Ahora',
        '3d': '3 días',
        '7d': '7 días',
        '10d': '10 días',
        '15d': '12 días',
        '30d': '30 días',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'El componente consulta los resultados de la base de datos relacional correspondiente mediante sentencias SQL. Soporta MySQL, PostgreSQL, MariaDB.',
      dbType: 'Tipo de base de datos',
      database: 'Base de datos',
      username: 'Nombre de usuario',
      host: 'Host',
      port: 'Puerto',
      password: 'Contraseña',
      switch: 'Cambiar',
      logicalOperator: 'Operador lógico',
      switchOperatorOptions: {
        equal: 'Igual',
        notEqual: 'No igual',
        gt: 'Mayor que',
        ge: 'Mayor o igual',
        lt: 'Menor que',
        le: 'Menor o igual',
        contains: 'Contiene',
        notContains: 'No contiene',
        startWith: 'Comienza con',
        endWith: 'Termina con',
        empty: 'Vacío',
        notEmpty: 'No vacío',
      },
      switchLogicOperatorOptions: {
        and: 'Y',
        or: 'O',
      },
      operator: 'Operador',
      value: 'Valor',
      useTemplate: 'Usar esta plantilla',
      wenCai: 'WenCai',
      queryType: 'Tipo de consulta',
      wenCaiDescription:
        'El componente se puede utilizar para obtener información en una amplia gama de áreas financieras, incluidas, entre otras, acciones, fondos, etc.',
      wenCaiQueryTypeOptions: {
        stock: 'Acción',
        zhishu: 'Índice',
        fund: 'Fondo',
        hkstock: 'Acciones de Hong Kong',
        usstock: 'Mercado de valores de EE. UU.',
        threeboard: 'Nuevo mercado OTC',
        conbond: 'Bono convertible',
        insurance: 'Seguro',
        futures: 'Futuros',
        lccp: 'Financiamiento',
        foreign_exchange: 'Divisas',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Este componente se puede usar para obtener información de noticias para la acción correspondiente del sitio web de Eastmoney.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'El componente consulta información sobre la empresa en función del símbolo bursátil proporcionado.',
      info: 'Información',
      history: 'Historial',
      financials: 'Finanzas',
      balanceSheet: 'Hoja de balance',
      cashFlowStatement: 'Estado de flujo de caja',
      jin10: 'Jin10',
      jin10Description:
        'Este componente se puede utilizar para acceder a información del sector financiero desde la plataforma abierta Jin10, incluidos noticias rápidas, calendario, cotizaciones, referencias.',
      flashType: 'Tipo de flash',
      filter: 'Filtro',
      contain: 'Contiene',
      calendarType: 'Tipo de calendario',
      calendarDatashape: 'Forma de datos del calendario',
      symbolsDatatype: 'Tipo de datos de símbolos',
      symbolsType: 'Tipo de símbolos',
      jin10TypeOptions: {
        flash: 'Noticias rápidas',
        calendar: 'Calendario',
        symbols: 'Cotizaciones',
        news: 'Referencias',
      },
      jin10FlashTypeOptions: {
        '1': 'Noticias del mercado',
        '2': 'Noticias de futuros',
        '3': 'Noticias de EE. UU. y Hong Kong',
        '4': 'Noticias de acciones A',
        '5': 'Noticias de productos y divisas',
      },
      jin10CalendarTypeOptions: {
        cj: 'Calendario de datos macroeconómicos',
        qh: 'Calendario de futuros',
        hk: 'Calendario del mercado de acciones de Hong Kong',
        us: 'Calendario del mercado de acciones de EE. UU.',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Datos',
        event: 'Evento',
        holiday: 'Festivo',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Cotizaciones de productos',
        FOREX: 'Cotizaciones de divisas',
        FUTURE: 'Cotizaciones del mercado internacional',
        CRYPTO: 'Cotizaciones de criptomonedas',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Lista de productos',
        quotes: 'Últimas cotizaciones del mercado',
      },
      concentrator: 'Concentrador',
      concentratorDescription:
        'Un componente que recibe la salida del componente anterior y la pasa como entrada a los componentes posteriores.',
      tuShare: 'TuShare',
      tuShareDescription:
        'Este componente se puede usar para obtener breves noticias financieras de sitios web financieros de referencia, lo que ayuda a la investigación industrial y cuantitativa.',
      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Flush recto',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },
      token: 'Token',
      src: 'Fuente',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de fin',
      keyword: 'Palabra clave',
      note: 'Nota',
      noteDescription: 'Nota',
      notePlaceholder: 'Por favor ingresa una nota',
    },
    footer: {
      profile: 'Todos los derechos reservados @ React',
    },
    layout: {
      file: 'Archivo',
      knowledge: 'Conocimiento',
      chat: 'Chat',
    },
  },
};
